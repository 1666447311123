var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "secure" },
    [
      _c("Header", { attrs: { title: "账户安全", back: false } }),
      _c("div", { staticClass: "box-items account-box" }, [
        _c("div", { staticClass: "secure-title theme-color" }, [
          _vm._v("账号管理"),
        ]),
        _c(
          "div",
          {
            staticClass: "flex-box border-line",
            on: {
              click: function ($event) {
                return _vm.changeAccount("tel")
              },
            },
          },
          [
            _c("div", [_vm._v("手机")]),
            _c("div", [
              _vm._v(
                _vm._s(_vm.info.tel ? _vm.formatHide(_vm.info.tel) : "未绑定")
              ),
              _c("i", { staticClass: "el-icon-arrow-right" }),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "flex-box",
            on: {
              click: function ($event) {
                return _vm.changeAccount("email")
              },
            },
          },
          [
            _c("div", [_vm._v("邮箱")]),
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.info.email ? _vm.formatHide(_vm.info.email) : "未绑定"
                )
              ),
              _c("i", { staticClass: "el-icon-arrow-right" }),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "box-items account-box" }, [
        _c("div", { staticClass: "secure-title theme-color" }, [
          _vm._v("密码管理"),
        ]),
        _c(
          "div",
          {
            staticClass: "flex-box border-line",
            on: {
              click: function ($event) {
                return _vm.changePwd("loginpassword")
              },
            },
          },
          [_c("div", [_vm._v("重置登录密码")]), _vm._m(0)]
        ),
        _c(
          "div",
          {
            staticClass: "flex-box",
            on: {
              click: function ($event) {
                return _vm.changePwd("tradepassword")
              },
            },
          },
          [_c("div", [_vm._v(_vm._s(_vm.transPassword))]), _vm._m(1)]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }