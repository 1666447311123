<template>
  <div class="secure">
    <Header :title="'账户安全'" :back="false"/>
    <div class="box-items account-box">
      <div class="secure-title theme-color">账号管理</div>
      <div class="flex-box border-line" @click="changeAccount('tel')">
        <div>手机</div>
        <div>{{info.tel?formatHide(info.tel):'未绑定'}}<i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="flex-box" @click="changeAccount('email')">
        <div>邮箱</div>
        <div>{{info.email?formatHide(info.email):'未绑定'}}<i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
    <div class="box-items account-box">
      <div class="secure-title theme-color">密码管理</div>
      <div class="flex-box border-line" @click="changePwd('loginpassword')">
        <div>重置登录密码</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="flex-box" @click="changePwd('tradepassword')">
        <div>{{transPassword}}</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      info:'',
      transPassword:''
    }
  },
  created(){
    this.$store.dispatch('transation/getUserAmount',{params:{code:'my',type:'info'}}).then(res=>{
      this.info =res.info
    })
    // this.info = JSON.parse(localStorage.getItem('info'));
    if(this.info.validate=='pass'){
      this.transPassword="重置交易密码"
    }else{
      this.transPassword="认证并设置交易密码"
    }
  },
  methods:{
    changeAccount(type){
      this.$router.push({path:'checks',query:{type:type}})
    },
    changePwd(type){
      if(type=='loginpassword'){
        this.$router.push({path:'changepwd',query:{type:type}})
      }else{
        if(this.info.validate=='pass'){
          this.$router.push({path:'changepwd',query:{type:type}})
        }else{
          this.$router.push({path:'auth',query:{type:type}})
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.secure{
  // background: #ffffff;
  .account-box{
    margin-top:2.5%;
    .secure-title{
      line-height: 2.4;
      font-weight: 600;
    }
    .flex-box{
      justify-content: space-between;
      color: #464646;
      line-height: 3;
    }
    .border-line{
      border-bottom: 1px solid #e4e4e4;
    }
  }
  
}
</style>